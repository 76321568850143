import { createContext, useMemo, useState } from "react";

export const GridContext = createContext({
  gridFilter: null,
  setGridFilter: () => {},
  gridVisible: false,
  setGridVisible: () => {},
  gridClickable: true,
  setGridClickable: () => {},
});

export const GridContextProvider = ({ children }) => {
  const [gridVisible, setGridVisible] = useState(false);
  const [gridClickable, setGridClickable] = useState(true);
  const [gridFilter, setGridFilter] = useState(null);

  const value = useMemo(
    () => ({
      gridVisible,
      setGridVisible,
      gridClickable,
      setGridClickable,
      gridFilter,
      setGridFilter,
    }),
    [gridVisible, gridClickable, gridFilter]
  );

  return <GridContext.Provider value={value}>{children}</GridContext.Provider>;
};
