import React, { useRef, useState, Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { langRoute, switchLang, dictionary } from "../util/lang";
import { menuItems, logRenders, logConsole } from "../util/global";

const Menu = ({ open, setQuery, setMenuOpen, lang }) => {
  logRenders && logConsole && console.log("RENDER — Menu component");

  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  // logConsole && console.log(">>>>>>>>>>>>>>>>>", location);

  const searchInput = useRef(null);

  function handleClearSearch() {
    searchInput.current.value = "";
    searchInput.current.blur();
    setSearchQuery("");
  }

  function handleSubmit(event) {
    event.preventDefault();
    navigate(`/${langRoute("search", lang)}/${searchQuery}`);
    closeMenu();
  }

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav id="menu" data-open={open}>
      <div className="main">
        <div>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="search"
              onChange={(event) => setSearchQuery(event.target.value)}
              ref={searchInput}
            />
            {/*
            <button type="submit">Search</button>
            <button type="button" onClick={handleClearSearch}>
              Clear
            </button>
            */}
          </form>
          {searchQuery && (
            <p className="">
              <a onClick={handleSubmit} className="font-s white50 pointer">
                search (enter)
              </a>
              <a
                onClick={() => {
                  handleClearSearch();
                }}
                className="font-s white50 pointer ml-2"
              >
                &times;
              </a>
            </p>
          )}
        </div>
        {!searchQuery && (
          <Fragment>
            <p>
              <Link onClick={closeMenu} to={`/${langRoute("projects", lang)}`}>
                {dictionary.menu_projects[lang]}
              </Link>
            </p>
            <p>
              <Link onClick={closeMenu} to={`/${langRoute("awards", lang)}`}>
                {dictionary.menu_awards[lang]}
              </Link>
            </p>
            <p>
              <Link
                onClick={closeMenu}
                to={`/${langRoute("publications", lang)}`}
              >
                {dictionary.menu_publications[lang]}
              </Link>
            </p>
            <p>
              <Link onClick={closeMenu} to={`/${langRoute("news", lang)}`}>
                {dictionary.menu_news[lang]}
              </Link>
            </p>
            <p>
              <Link onClick={closeMenu} to={`/${langRoute("team", lang)}`}>
                {dictionary.menu_team[lang]}
              </Link>
            </p>
            <p>
              <Link onClick={closeMenu} to={`/${langRoute("about", lang)}`}>
                {dictionary.menu_about[lang]}
              </Link>
            </p>
            <p>
              <Link onClick={closeMenu} to={`/${langRoute("contact", lang)}`}>
                {dictionary.menu_contact[lang]}
              </Link>
            </p>
          </Fragment>
        )}
      </div>
      {!searchQuery && (
        <div className="social">
          {menuItems
            .filter((e) => e.type == "external-link")
            .map((e, i) => (
              <p key={i}>
                <a
                  className="white50"
                  href={e.id}
                  target="_blank"
                  rel="noreferrer"
                >
                  {e.pageTitle[lang]}
                </a>
              </p>
            ))}
        </div>
      )}
      <div className="spacer" />
      <div className="bottom">
        <Link
          className={`white50${lang === "de" ? " active" : ""}`}
          to={switchLang(location.pathname, lang, "de")}
        >
          de
        </Link>
        <span className="white50 font-s">{" / "}</span>
        <Link
          className={`white50 ml-0${lang === "en" ? " active" : ""}`}
          to={switchLang(location.pathname, lang, "en")}
        >
          en
        </Link>
        <Link className="white50" to={`/${langRoute("privacy", lang)}`}>
          {dictionary.menu_privacy[lang]}
        </Link>
        <Link className="white50" to={`/${langRoute("imprint", lang)}`}>
          {dictionary.menu_imprint[lang]}
        </Link>
      </div>
    </nav>
  );
};

export default React.memo(Menu);
