const Dot = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 10"
    version="1.1"
    className="dot"
    width="10px"
    height="10px"
  >
    <g
      className="dot-g"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <circle
        // fill="none"
        cx="5"
        cy="5"
        r="1.5"
      ></circle>
    </g>
  </svg>
);

export default Dot;
