// -----------------------------------------------
// Global data
// -----------------------------------------------

export const logConsole = false;
export const logRenders = false;
export const menuItems = [
  {
    type: "main-item",
    id: "search",
    pageTitle: { de: "search", en: "search" },
  },
  {
    type: "main-item",
    id: "projects",
    pageTitle: { de: "projekte", en: "projects" },
  },
  {
    type: "main-item",
    id: "awards",
    pageTitle: { de: "preise", en: "awards" },
  },
  {
    type: "main-item",
    id: "publications",
    pageTitle: { de: "publikationen", en: "publications" },
  },
  {
    type: "main-item",
    id: "news",
    pageTitle: { de: "news", en: "news" },
  },
  {
    type: "main-item",
    id: "team",
    pageTitle: { de: "team", en: "team" },
  },
  {
    type: "main-item",
    id: "about",
    pageTitle: { de: "philosophie", en: "about" },
  },
  {
    type: "main-item",
    id: "contact",
    pageTitle: { de: "kontakt", en: "contact" },
  },
  {
    type: "external-link",
    id: "https://www.instagram.com/stauss_processform/",
    pageTitle: { de: "instagram", en: "instagram" },
  },
  {
    type: "external-link",
    id: "https://www.facebook.com/246982508722742/",
    pageTitle: { de: "facebook", en: "facebook" },
  },
  {
    type: "external-link",
    id: "https://www.linkedin.com/in/kilian-stauss-7857907b/detail/recent-activity/shares/",
    pageTitle: { de: "linkedin", en: "linkedin" },
  },
  {
    type: "external-link",
    id: "https://vimeo.com/stauss",
    pageTitle: { de: "vimeo", en: "vimeo" },
  },
  {
    type: "small-item",
    id: "privacy",
    pageTitle: { de: "datenschutz", en: "privacy" },
  },
  {
    type: "small-item",
    id: "imprint",
    pageTitle: { de: "impressum", en: "imprint" },
  },
];

export const adMeta = [
  {
    id: "furniture",
    slug: { de: "furniture-design", en: "furniture-design" },
    name: { de: "furniture design", en: "furniture design" },
    pageId: "ad_furniture",
  },
  {
    id: "graphic",
    slug: { de: "graphic-design", en: "graphic-design" },
    name: { de: "graphic design", en: "graphic design" },
    pageId: "ad_graphic",
  },
  {
    id: "interface",
    slug: { de: "interface-design", en: "interface-design" },
    name: { de: "interface design", en: "interface design" },
    pageId: "ad_interface",
  },
  {
    id: "product",
    slug: { de: "product-design", en: "product-design" },
    name: { de: "product design", en: "product design" },
    pageId: "ad_product",
  },
  {
    id: "signage",
    slug: { de: "signage-design", en: "signage-design" },
    name: { de: "signage design", en: "signage design" },
    pageId: "ad_signage",
  },
  {
    id: "spatial",
    slug: { de: "spatial-design", en: "spatial-design" },
    name: { de: "spatial design", en: "spatial design" },
    pageId: "ad_spatial",
  },
];

export const categoriesMeta = [
  {
    id: "furniture",
    slug: { de: "moebeldesign", en: "furniture-design" },
    pageId: "landing_furniture",
  },
  {
    id: "graphic",
    slug: { de: "grafikdesign", en: "graphic-design" },
    pageId: "landing_graphic",
  },
  {
    id: "interface",
    slug: { de: "interface-design", en: "interface-design" },
    pageId: "landing_interface",
  },
  {
    id: "product",
    slug: { de: "produktdesign", en: "product-design" },
    pageId: "landing_product",
  },
  {
    id: "signage",
    slug: { de: "leit-und-orientierungssysteme", en: "signage-design" },
    pageId: "landing_signage",
  },
  {
    id: "spatial",
    slug: { de: "raum-und-design", en: "spatial-design" },
    pageId: "landing_spatial",
  },
];
