import { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getDateVal, vBarValues } from "../util/functions";
import { logRenders, logConsole, menuItems } from "../util/global";
import { GridContext } from "./GridContext";

const List = ({
  lang,
  filter,
  items,
  setImgLeft,
  setVBarTemp,
  setVBarHard,
  listScrollPos,
  setListScrollPos,
}) => {
  const { searched } = useParams();
  logRenders && logConsole && console.log("RENDER — List component");
  const { setGridClickable } = useContext(GridContext);

  let filtered;
  if (filter === "search" && searched) {
    filtered = items.filter((item) => {
      const title =
        item.title[lang].toLowerCase().indexOf(searched.toLowerCase()) !== -1;
      const client =
        item.client.toLowerCase().indexOf(searched.toLowerCase()) !== -1;
      const keywords =
        item.keywords &&
        item.keywords.toLowerCase().indexOf(searched.toLowerCase()) !== -1;
      return client || title || keywords;
    });
  } else {
    filtered = items.filter((item) => item.type === filter);
  }

  const mapFilterToMenuItem = {
    project: "projects",
    award: "awards",
    publication: "publications",
    news: "news",
    search: "search",
  };

  useEffect(() => {
    if (filter) {
      const menuItem = menuItems.find(
        (e) => e.id === mapFilterToMenuItem[filter]
      );
      if (!menuItem) {
        throw new Error(
          `(23857238) no menu item with id ${mapFilterToMenuItem[filter]}`
        );
      }
      const title = menuItem.pageTitle[lang];
      setVBarHard({ top: title, year: "", bottom: "" });
    }
    if (filtered.length === 0) {
      return;
    }
    let i = 0;
    let img = undefined;
    while (!img && i < filtered.length) {
      const random = filtered[Math.floor(Math.random() * filtered.length)];
      img = random.firstImg ? random.firstImg.fullUrl : undefined;
      i++;
    }
    setImgLeft(img);
    setGridClickable(false);
  }, [filter, searched]);

  const handleMouseEnter = (item, lang) => {
    setVBarTemp(vBarValues(item, lang));
  };

  const handleMouseLeave = () => {
    setVBarTemp(null);
  };

  const saveListPosition = () => {
    const thisPosition = {};
    thisPosition[filter] = document.querySelector("#content").scrollTop;
    const newListPositions = { ...listScrollPos, ...thisPosition };
    setListScrollPos(newListPositions);
  };

  const handleItemClick = () => {
    setVBarTemp(null);
    saveListPosition();
  };

  return (
    <div className="list">
      {searched && (
        <p className="font-s text-margin mb-3">
          <span className="mr-2">{`'${searched}'`}</span>
          <span className="white50">{`${
            filtered.length ? filtered.length : "no"
          } results`}</span>
        </p>
      )}
      {filtered.length > 0 &&
        filtered
          .sort((a, b) => getDateVal(b) - getDateVal(a))
          .map((item, i) => {
            return (
              <p key={i} className="list-item" title={item.originalId}>
                <Link
                  to={item.path[lang]}
                  onMouseEnter={() => {
                    handleMouseEnter(item, lang);
                  }}
                  onMouseLeave={() => {
                    handleMouseLeave();
                  }}
                  onClick={() => {
                    handleItemClick();
                  }}
                  // title={item.year +"---"+ item.date}
                >
                  {item.title[lang]}
                  <br />
                  {item.client}
                </Link>
              </p>
            );
          })}
      {/*
      {filtered.length == 0 && (
        <p>no results</p>
      )}
      */}
    </div>
  );
};

export default List;
