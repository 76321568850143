import { useState, useEffect, useContext } from "react";
import { getPage } from "../util/api";
import Dot from "./Dot";
import { logConsole } from "../util/global";
import { GridContext } from "./GridContext";
// import {isValidCategory, getCategoryId} from "../util/functions";

const Home = ({ category, lang, setVBarHard }) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(null);
  const { setGridVisible, setGridFilter, setGridClickable } =
    useContext(GridContext);

  const pageId = "home";

  useEffect(() => {
    // const categoryId = getCategoryId(category, lang);
    setVBarHard({ top: "", year: "", bottom: "" });
    setGridVisible(true);
    setGridClickable(true);
    setGridFilter(null);
    getPage(pageId, lang, (page) => {
      setLoading(false);
      setPage(page);
    });
  }, [category]);

  useEffect(() => {
    setGridVisible(true);
    setGridClickable(true);
  });

  logConsole && console.log("page", page);

  if (loading || !page) {
    return (
      // <div className="msg">{"•••"}</div>
      <div id="spinner">
        <Dot />
      </div>
    );
  }

  if (!page.imgs || page.imgs.length === 0 || !page.imgs[0].fullUrl) {
    return <div className="msg">No images</div>;
  }

  return (
    <div className="category-landing">
      <div
        className="bg"
        style={{ backgroundImage: `url(${page.imgs[0].fullUrl})` }}
      />
      <div className="text" dangerouslySetInnerHTML={{ __html: page.text }} />
    </div>
  );
};

export default Home;

// import React, {useEffect} from "react";
// import {logRenders} from "../util/global"
// import {dictionary} from "../util/lang"

// const Home = ({lang, setImgRight, setGridVisible, setGridFilter}) => {
//   logRenders && logConsole && console.log("RENDER — Home component");

//   useEffect(() => {
//     setGridVisible(true);
//     // debugger;
//     setGridFilter(null);
//   }, []);

//   return (
//     <div className="homepage">
//       <div className="bg" style={{backgroundImage: `url(https://picsum.photos/800/1200)`}} />
//       <div className="text">{dictionary.landingAboutIncipit[lang]}</div>
//     </div>
//   );
// }

// export default React.memo(Home);
