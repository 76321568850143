import { logConsole, logRenders } from "./util/global";

const Reducer = (state, action) => {
  logConsole && console.log("reducer", action);
  switch (action.type) {
    case "SET_ITEMS":
      return { ...state, items: action.payload };

    // case 'SET_VBAR?':
    //   return {...state, lang: action.payload};

    default:
      return state;
  }
};

export default Reducer;
