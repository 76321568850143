import React, { useContext, useEffect, useState } from "react";
// import {getItem, contentVersionsTitle, lazySrcsetImgObject} from "../util/api";
import { dictionary } from "../util/lang";
import { isValidCategory, getCategoryId, vBarValues } from "../util/functions";
import { Navigate, useParams } from "react-router-dom";
import { logConsole, logRenders } from "../util/global";
import LazyImage from "./LazyImage";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";
import { GridContext } from "./GridContext";

const Video = ({ video }) => {
  // logConsole && console.log(">>>>>>>>>>>>>>>",video)
  return (
    <figure className="responsive-video-embed">
      <div
        className="video-wrapper"
        style={{ paddingBottom: `${video.ratio}%` }}
      >
        <iframe
          allowFullScreen
          src={`https://player.vimeo.com/video/${video.vimeoId}?title=0&byline=0&portrait=0`}
          title={video.vimeoId}
        ></iframe>
      </div>
    </figure>
  );
};

const Item = ({ type, items, lang, setImgLeft, setVBarHard }) => {
  const { category, slug } = useParams();
  const { setGridFilter, setGridClickable } = useContext(GridContext);

  logRenders &&
    logConsole &&
    console.log(
      "RENDER — Item component",
      items.find((e) => e.slug === slug)
    );

  if (type === "project") {
    if (!isValidCategory(category, lang)) {
      throw new Error(`(42096032) invalid category ${category}`);
      // return (
      //   <Redirect to={`/${lang}/404`} noThrow />
      // );
    }
  }

  // --- find item in items array
  const item = items.length > 0 ? items.find((d) => d.slug === slug) : null;
  if (!item && items.length !== 0) {
    throw new Error(`(02457463) item '${slug}' not present in items`);
  }

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (item) {
      logConsole && console.log("Updating vbar", item);
      setVBarHard(vBarValues(item, lang));
      setImgLeft(item.firstImg.fullUrl);
      //setExpanded(false); // breaks toggle
      if (item.type === "project") {
        setGridFilter(item.category);
      }
      setGridClickable(true);
    }
  });
  //}, [slug]);

  if (items.length === 0) {
    // AFTER useEffect()
    return null;
  }

  // --- if wrong category name
  if (item.type === "project") {
    const categoryId = getCategoryId(category, lang);
    if (item.category !== categoryId) {
      return <Navigate replace to={item.path[lang]} noThrow />;
    }
  }

  // --- moved from api to here
  const showMore = dictionary.showMore[lang];
  const showLess = dictionary.showLess[lang];
  const textFull = item.text.hasOwnProperty(lang) ? item.text[lang] : "";
  var textShort = "";
  var thereIsMore = false;
  var maxWords = 40;
  var wordCount = textFull.split(" ").length;
  if (wordCount > maxWords) {
    thereIsMore = true;
    textShort = textFull.split(" ").splice(0, maxWords).join(" ") + "…";
  } else {
    textShort = textFull;
  }
  // srcset images
  // var firstImgLazySrcset = (item.imgs.length == 0) ? false : lazySrcsetImgObject(item.imgs[0]);
  // var secondImgLazySrcset = (item.imgs.length < 2) ? false : lazySrcsetImgObject(item.imgs[1]);
  // var imgsLazySrcset = item.imgs.filter((e, i) => (i > 1)).map((e) => lazySrcsetImgObject(e));
  const theresLinks = item.links[lang] && item.links[lang].length > 0;

  // --- update vBar
  // const top = item.type === "project"
  //   ? item.category
  //   : item.type;
  // const year = item.year;
  // const bottom = `${item.title[lang]}\n${item.client}`;

  // logConsole && console.log(">>>>>>>>>>>>>>>",item)
  return (
    <div className="item">
      {/*!item.firstImg && (
        <div className="compensate-top-margin d-xl-none" />
      )*/}
      {item.firstImg && (
        <LazyImage
          src={item.firstImg.fullUrl}
          key={`first-${slug}`}
          id={`first-${slug}`}
          className="d-xl-none"
        />
      )}
      {/*!item.secondImg && (
        <div className="compensate-top-margin d-none d-xl-block" />
      )*/}
      {item.secondImg && (
        <LazyImage
          src={item.secondImg.fullUrl}
          key={`seca-${slug}`}
          id={`seca-${slug}`}
          className="d-none d-xl-block"
        />
      )}
      {textShort && !expanded && (
        <div className="text-webpblpage">
          {textShort}
          {thereIsMore && (
            <a
              className="pointer white50"
              onClick={() => {
                setExpanded(true);
              }}
            >
              {" " + showMore}
            </a>
          )}
        </div>
      )}
      {textShort && expanded && (
        <div className="text-webpblpage">
          {textFull}
          <a
            className="pointer white50"
            onClick={() => {
              setExpanded(false);
            }}
          >
            {" " + showLess}
          </a>
        </div>
      )}
      {item.secondImg && (
        <LazyImage
          src={item.secondImg.fullUrl}
          key={`secb-${slug}`}
          id={`secb-${slug}`}
          className="d-xl-none"
        />
      )}
      {item.videos.map((v, i) => (
        <Video key={i} video={v} />
      ))}
      {item.imgs && item.imgs.length > 1 && (
        <div className="imgs-wrapper">
          {item.imgs
            .filter((e, i) => i > 1)
            .map((img, i) => (
              <LazyImage
                src={img.fullUrl}
                key={`${i}-${slug}`}
                id={`${i}-${slug}`}
              />
            ))}
        </div>
      )}
      {theresLinks && (
        <div className="links">
          <p className="m-0 font-s white50">links</p>
          {item.links[lang].map((link, i) => (
            <p key={i} className="m-0 font-s">
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  link.link_url?.match(/(?:^[a-z][a-z0-9+\.-]*:|\/\/)/gu) // regex to check if absolute url is present, also handles cases like mailto: & tel:
                    ? link.link_url
                    : `http://${link.link_url}` // if not, prepend http:// (should be https:// but some links don't have https)
                }
              >
                {link.link_cont}
              </a>
            </p>
          ))}
        </div>
      )}

      <div className="links share">
        <p className="m-0 font-s">
          <span className="white50">share</span>
        </p>
        <p className="m-0 font-s">
          {" "}
          <FacebookShareButton url={window.location}>
            facebook
          </FacebookShareButton>{" "}
          <LinkedinShareButton url={window.location}>
            linkedin
          </LinkedinShareButton>{" "}
          <TelegramShareButton url={window.location}>
            telegram
          </TelegramShareButton>{" "}
          <WhatsappShareButton url={window.location}>
            whatsapp
          </WhatsappShareButton>
          {/* window.location */}
          {/*"http://piaaaac.net"+ location.pathname*/}
        </p>
      </div>

      {/*item.imgs && (item.imgs.length <= 2) && !theresLinks && (
        <div className="compensate-bottom-margin" />
      )*/}
    </div>
  );
};

export default React.memo(Item);
