import { logConsole } from "../util/global";
import { useState, useEffect, useContext } from "react";
import { getPage, mapAdToPage } from "../util/api";
import { getAdId, getAdName, scrollToPixel } from "../util/functions";
import Dot from "./Dot";
import { useParams } from "react-router-dom";
import { GridContext } from "./GridContext";

const AdLanding = ({ lang, setVBarHard }) => {
  const { category } = useParams();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(null);
  const pageId = mapAdToPage(category, lang);
  const { setGridClickable, setGridFilter, setGridVisible } =
    useContext(GridContext);

  useEffect(() => {
    const categoryId = getAdId(category, lang);
    const categoryName = getAdName(category, lang);
    setVBarHard({ top: "", year: "", bottom: "", center: categoryName });
    setGridClickable(true);
    setGridVisible(true);
    setGridClickable(false);
    setGridFilter(categoryId);
    getPage(pageId, lang, (page) => {
      setLoading(false);
      setPage(page);
    });
  }, [category]);

  useEffect(() => {
    setGridClickable(true);
    setGridVisible(true);
  });

  /* check not needed for ad landings
  if (!isValidCategory(category, lang)) {
    throw new Error(`(03827645) invalid catoegory '${category}' for '${lang}'`);
  }
  */
  if (loading || !page) {
    return (
      <div id="spinner">
        <Dot />
      </div>
    );
  }
  const categoryId = getAdId(category, lang);

  const scrollToForm = () => {
    scrollToPixel(document.getElementById("contact-form").offsetTop);
  };

  const submitForm = (form, callback) => {
    fetch("https://www.processform.de", {
      method: "POST",
      body: new FormData(form),
    })
      .then((response) => response.json())
      .then((data) => {
        callback();
      });
  };

  const didSendForm = () => {
    document.getElementById("formfields").classList.add("hidden");
    document.getElementById("submit").classList.add("noshow");
    document.getElementById("formsend").classList.remove("hidden");

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "FormSend" });
  };

  const sendForm = (e) => {
    e.preventDefault();
    submitForm(e.target, didSendForm);
  };

  logConsole && console.log("page", page);
  const img1 = page.imgs[0].fullUrl;
  const img2 = page.imgs.length > 1 ? page.imgs[1].fullUrl : img1;

  return (
    <div>
      <div className="ad-landing">
        <div className="bg" style={{ backgroundImage: `url(${img1})` }} />
        <div
          className="text font-s text-pt"
          dangerouslySetInnerHTML={{ __html: page.text }}
        />
        <div className="info font-s">
          <div>
            <div className="btn-wrapper">
              <button onClick={scrollToForm} className="contact-us">
                {lang === "de" && <span>contact us!</span>}
                {lang === "en" && <span>contact us!</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
      <form
        className="ad-landing"
        id="contact-form"
        method="POST"
        action="https://www.processform.de"
        onSubmit={sendForm}
      >
        <input
          className="potty"
          id="website"
          name="website"
          type="text"
          value=""
        />
        <input type="hidden" name="categoryid" value={categoryId} />
        <div className="bg" style={{ backgroundImage: `url(${img2})` }} />
        <div className="text font-s">
          <div className="page pb-0 pt-0">
            <div className="layoutArea">
              <div className="column">
                <div id="formfields">
                  <div className="intro">
                    {lang === "de" && (
                      <span>Wir freuen uns auf Ihre Anfrage!</span>
                    )}
                    {lang === "en" && (
                      <span>We look forward to your enquiry!</span>
                    )}
                  </div>
                  <div className="fieldset">
                    <input
                      type="text"
                      name="nachname"
                      placeholder={lang === "de" ? "Name" : "Last name"}
                      required
                    />
                    <input
                      type="text"
                      name="vorname"
                      placeholder={lang === "de" ? "Vorname" : "First name"}
                      required
                    />
                  </div>
                  <input
                    type="text"
                    name="firma"
                    placeholder={lang === "de" ? "Firma" : "Company"}
                  />
                  <div className="fieldset">
                    <input
                      type="text"
                      name="telefon"
                      placeholder={lang === "de" ? "Telefon" : "Phone"}
                    />
                    <input
                      type="email"
                      name="email"
                      placeholder="E-Mail"
                      required
                    />
                  </div>
                  <label className="checkbox">
                    {lang === "de" && <span>Bitte um R&uuml;ckruf</span>}
                    {lang === "en" && <span>Callback</span>}
                    <input type="checkbox" name="rueckruf" />
                    <span className="checkmark" />
                  </label>
                  <label className="checkbox">
                    {lang === "de" && <span>Bitte um Terminvereinbarung</span>}
                    {lang === "en" && <span>Appointment</span>}
                    <input type="checkbox" name="terminvereinbarung" />
                    <span className="checkmark" />
                  </label>
                  <label className="checkbox">
                    {lang === "de" && <span>Bitte um Angebot</span>}
                    {lang === "en" && <span>Offer</span>}
                    <input type="checkbox" name="angebot" />
                    <span className="checkmark" />
                  </label>
                  <textarea
                    name="nachricht"
                    rows="3"
                    placeholder={
                      lang === "de"
                        ? "weitere Angaben (optional)"
                        : "optional Message"
                    }
                  />
                </div>
                <div id="formsend" className="hidden">
                  {lang === "de" && (
                    <span>Vielen dank. Wir melden uns bei Ihnen.</span>
                  )}
                  {lang === "en" && (
                    <span>
                      Thank you. We will get back to you as soon as possible.
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="info font-s">
          <div>
            <div id="submit" className="btn-wrapper">
              <button
                type="submit"
                onClick={scrollToForm}
                className="contact-us"
              >
                {lang === "de" && <span>send!</span>}
                {lang === "en" && <span>send!</span>}
              </button>
            </div>
            <div className="contact">
              sandstra&szlig;e 33
              <br />
              80335 m&uuml;nchen
              <br />
              fon +49 (0) 89 21 57 88 62
              <br />
              fax +49 (0) 89 21 57 88 63
              <br />
              <a href="mailto:stauss@processform.de">stauss@processform.de</a>
              <br />
              <a href="https://www.processform.de">www.processform.de</a>
            </div>
            <div className="imprint">
              <a href="https://www.processform.de/de/impressum">
                {lang === "de" && <span>impressum</span>}
                {lang === "en" && <span>imprint</span>}
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdLanding;
