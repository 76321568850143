import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { logConsole, logRenders } from "../util/global";

const LazyImage = ({ src, id, className, alt = "image-alt" }) => (
  <div
    id={`wrapper-${id}`}
    className={`lazy-img loading${className ? ` ${className}` : ""}`}
  >
    <LazyLoadImage
      key={id}
      alt={alt}
      effect="opacity"
      src={src}
      afterLoad={() => {
        logConsole && console.log(`image ${id} loaded`);
        document.getElementById(`wrapper-${id}`).classList.remove("loading");
      }}
    />
  </div>
);

export default LazyImage;
