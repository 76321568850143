import "./style/index.scss";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Store from "./Store";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Err404 from "./components/Err404";
import { GridContextProvider } from "./components/GridContext";

const browserLang = navigator.language.substring(0, 2) === "de" ? "de" : "en";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Store>
        <GridContextProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                exact
                element={<Navigate replace to={browserLang} noThrow />}
              />
              <Route path="en/*" element={<App lang="en" />} />
              <Route path="de/*" element={<App lang="de" />} />
              <Route path="*" element={<Err404 lang={browserLang} />} />
            </Routes>
          </BrowserRouter>
        </GridContextProvider>
      </Store>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
