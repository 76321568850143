import React from "react";
import { logConsole, logRenders } from "../util/global";

const Hamburger = ({ isOpen, callback }) => {
  logConsole && console.log("RENDER — Hamburger component");

  const handleClick = () => {
    const newVal = !isOpen;
    callback(newVal);
  };

  return (
    <nav id="hamburger">
      <input type="checkbox" checked={isOpen} onClick={handleClick} readOnly />
      <span></span>
      <span></span>
      <span></span>
    </nav>
  );
};

export default React.memo(Hamburger);
