import { adMeta, categoriesMeta, logConsole } from "./global";
import { getAdId, getCategoryId } from "./functions";
// import {dictionary} from "./lang";

/*

current site
json={"query":[["route","\"/en/team\""],["route","\"/en/team_kilianstauss\""],["route","\"/en/team_kathrinstauss\""],["route","\"/en/team_janisnecker\""],["route","\"/en/team_samanthamariuzzi\""],["route","\"/en/team_telmogermano\""],"OR"]}


json={"query":[["route","\"/en/team\""],["route","\"/en/team_kilianstauss\""],["route","\"/en/team_kathrinstauss\""],["route","\"/en/team_janisnecker\""],["route","\"/en/team_samanthamariuzzi\""],["route","\"/en/team_telmogermano\""],"OR"]}


*/
// ---------------------------------------------------
// Helper data
// ---------------------------------------------------

const convertKeyThumb = "jpg80_r_300x0.jpg";
const convertKeyFull = "jpg90_r_1120x0.jpg";
const convertKeysSrcset = [
  ["jpg80_r_579x0.jpg", "579w"],
  ["jpg80_r_680x0.jpg", "680w"],
  ["jpg90_r_1120x0.jpg", "1120w"],
];

const mapTypeToPath = {
  award: { en: "awards", de: "preise" },
  news: { en: "news", de: "news" },
  project: { en: "projects", de: "projekte" },
  publication: { en: "publications", de: "publikationen" },
};

const pagesApiBinding = {
  home: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/613035f9ccb3aedcc3367a76cda5cb38",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/613035f9ccb3aedcc3367a76cda68755",
      data: null,
    },
  },

  team: {
    de: {
      method: "POST",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/views/website?sort=name&limit=10&offset=0",
      data: {
        query: [
          ["route", '"/de/team"'],
          ["route", '"/de/team_kilianstauss"'],
          ["route", '"/de/team_kathrinstauss"'],
          ["route", '"/de/team_janisnecker"'],
          ["route", '"/de/team_samanthamariuzzi"'],
          ["route", '"/de/team_telmogermano"'],
          "OR",
        ],
      },
    },
    en: {
      method: "POST",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/views/website?sort=name&limit=10&offset=0",
      data: {
        query: [
          ["route", '"/en/team"'],
          ["route", '"/en/team_kilianstauss"'],
          ["route", '"/en/team_kathrinstauss"'],
          ["route", '"/en/team_janisnecker"'],
          ["route", '"/en/team_samanthamariuzzi"'],
          ["route", '"/en/team_telmogermano"'],
          "OR",
        ],
      },
    },
  },

  about: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a94e166f3e06180f67ffa270b24db05f",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/8594d411d333eb57493957a350f38856",
      data: null,
    },
  },

  contact: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a94e166f3e06180f67ffa270b24d1658",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/501c44035123b916b219b590386c567f",
      data: null,
    },
  },

  privacy: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/8594d411d333eb57493957a350f68c75",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/8594d411d333eb57493957a350f60536",
      data: null,
    },
  },

  imprint: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/abe4defcca347ae616ef0ece7a2b352c",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/abe4defcca347ae616ef0ece7a2c050a",
      data: null,
    },
  },

  // --- Ad landings -----------------------------------------------------

  ad_graphic: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/2ee6d9c250208916635b95bf7a08bf9f",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/2ee6d9c250208916635b95bf7a095943",
      data: null,
    },
  },

  ad_interface: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/2ee6d9c250208916635b95bf7a0faf33",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/2ee6d9c250208916635b95bf7a10267b",
      data: null,
    },
  },

  ad_signage: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/2ee6d9c250208916635b95bf7a0e7b6b",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/2ee6d9c250208916635b95bf7a0f00d8",
      data: null,
    },
  },

  ad_furniture: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/2ee6d9c250208916635b95bf7a0b463c",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/2ee6d9c250208916635b95bf7a0a9df1",
      data: null,
    },
  },

  ad_product: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/2ee6d9c250208916635b95bf7a0d2f53",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/2ee6d9c250208916635b95bf7a0dc579",
      data: null,
    },
  },

  ad_spatial: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/2ee6d9c250208916635b95bf7a0be65f",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/2ee6d9c250208916635b95bf7a0c90ce",
      data: null,
    },
  },

  // --- Category landings -----------------------------------------------------

  landing_graphic: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/0748382eed81bb4848f69d3c9caaa941",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a0ea077eaae5d27d2a8fb565e41062e3",
      data: null,
    },
  },

  landing_interface: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a0ea077eaae5d27d2a8fb565e4073055",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a0ea077eaae5d27d2a8fb565e412a2a9",
      data: null,
    },
  },

  landing_signage: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a0ea077eaae5d27d2a8fb565e40b1fa3",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a0ea077eaae5d27d2a8fb565e4148a4b",
      data: null,
    },
  },

  landing_furniture: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a0ea077eaae5d27d2a8fb565e409af3a",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a0ea077eaae5d27d2a8fb565e4118ad9",
      data: null,
    },
  },

  landing_product: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a0ea077eaae5d27d2a8fb565e408443e",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a0ea077eaae5d27d2a8fb565e413dd3b",
      data: null,
    },
  },

  landing_spatial: {
    de: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a0ea077eaae5d27d2a8fb565e40da3d7",
      data: null,
    },
    en: {
      method: "GET",
      url: "https://processform.frontend.biz/api/public/modules/webpblpage/document/a0ea077eaae5d27d2a8fb565e40f0f57",
      data: null,
    },
  },
};

const xhrCall = (url, data, method, cb) => {
  function reqListener() {
    cb(this.response);
  }
  var xhr = new XMLHttpRequest();
  xhr.addEventListener("load", reqListener);
  if (method.toUpperCase() === "GET") {
    xhr.open("GET", url);
    xhr.send();
  } else if (method.toUpperCase() === "POST") {
    xhr.open("POST", url);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    var formData = "json=" + JSON.stringify(data);
    xhr.send(formData);
  }
};

export const getItems = (cb) => {
  const req = {
    method: "POST",
    url: "https://processform.frontend.biz/api/public/modules/content/views/website?sort=name&limit=1000&offset=0",
    data: {
      query: [
        ["content_type", "project"],
        ["content_type", "news"],
        ["content_type", "award"],
        ["content_type", "publication"],
        "OR",
      ],
    },
  };
  xhrCall(req.url, req.data, req.method, (response) => {
    const parsed = JSON.parse(response);
    logConsole && console.log("API response (items):", parsed);
    const list = parsed.data.list;

    // var startTime = performance.now();
    const items = list.map((d) => cleanApiItem(d));
    // var endTime = performance.now();
    // var totalTime = endTime - startTime; // time took to run in milliseconds
    // var weight = JSON.stringify(items).length;
    // logConsole && console.log("Resources used:", {
    // "time": totalTime,
    // "weight": weight,
    // });
    cb(items);
  });
};

export const getPage = (id, lang, cb) => {
  const req = pagesApiBinding[id][lang];
  xhrCall(req.url, req.data, req.method, (response) => {
    const parsed = JSON.parse(response);
    logConsole && console.log("API response (page):", parsed);
    const page = cleanApiPage(parsed, id);
    cb(page);
  });
};

// export const getItem = (originalId, cb) => {
//   const req = {
//     "method": "GET",
//     "url": "https://processform.frontend.biz/api/public/modules/webpblpage/document/"+ originalId,
//     "data": null
//   };
//   xhrCall(req.url, req.data, req.method, cb);
// }
export const getItem = (slug, cb) => {
  const req = {
    method: "POST",
    url: "https://processform.frontend.biz/api/public/modules/content/views/website?sort=name&limit=10&offset=0",
    data: { query: ["slug", '"' + slug + '"'] },
  };
  xhrCall(req.url, req.data, req.method, cb);
};

export const mapAdToPage = (category, lang) => {
  const categoryId = getAdId(category, lang);
  return adMeta.find((e) => e.id === categoryId).pageId;
};

export const mapCategoryToPage = (category, lang) => {
  const categoryId = getCategoryId(category, lang);
  return categoriesMeta.find((e) => e.id === categoryId).pageId;
};

// -----------------------------------------------
// Data normalization
// -----------------------------------------------

// function cleanApiItemLight (d) {
//   var title = contentVersionsTitle(d);
//   var images = d.media.filter((e, i) => {
//     return e.hasOwnProperty("media_object") && e.media_object.hasOwnProperty("webCacheUrl");
//   });
//   var firstImg = (images.length == 0) ? false : cleanImgObject(images[0]);
//   var path = itemPathFromVersions(d);
//   return {
//     "category": d.category,
//     "client": d.customer ? d.customer : "",
//     "code": d.fn,
//     "firstImg": firstImg,
//     "originalId": d.id,
//     "path": path,
//     "slug": d.slug,
//     "thumbX": (Math.random() > 0.5) ? "left" : "right",
//     "thumbY": (Math.random() > 0.5) ? "top" : "bottom",
//     "title": title,
//     "type": d.content_type,
//     "year": d.year,
//   };
// }

function cleanApiItem(d) {
  var text = contentTextVersions(d);
  var path = itemPathFromVersions(d);

  // --- validate media items
  var images = d.media.filter(function (e, i) {
    return (
      e.hasOwnProperty("media_object") &&
      e.media_object.hasOwnProperty("webCacheUrl")
    );
  });
  var videos = extractVideos(d);
  var totImgCount = images.length;
  var firstImg = images.length === 0 ? false : cleanImgObject(images[0]);
  var secondImg = images.length < 2 ? false : cleanImgObject(images[1]);
  var imgs = images.map((e) => cleanImgObject(e));
  var title = contentVersionsTitle(d); /*[lang]*/
  var links = contentVersionsLinks(d); /*[lang]*/
  if (!firstImg) {
    logConsole && console.log("---> No images", d.id);
  }
  if (d.content_type === "project" && !d.category) {
    logConsole && console.log("------> No category", d.id);
  }

  return {
    category: d.category,
    client: d.customer ? d.customer : "",
    code: d.fn,
    date: d.date,
    firstImg: firstImg,
    secondImg: secondImg,
    originalId: d.id,
    path: path,
    slug: d.slug,
    thumbX: Math.random() > 0.5 ? "left" : "right",
    thumbY: Math.random() > 0.5 ? "top" : "bottom",
    title: title,
    type: d.content_type,
    year: d.year,

    description: d.description,
    text: text,
    links: links,
    keywords: d.keywords,
    videos: videos,
    totImgCount: totImgCount,
    imgs: imgs,
  };
}

function cleanApiPage(response, id) {
  const isTeam = id === "team";

  if (isTeam) {
    if (!response.data) {
      throw new Error("(74569238) no 'data' property in 'response'");
    } else if (!response.data.list) {
      throw new Error("(23987438) no 'list' property in 'response.data'");
    }
    var order = [
      "/de/team",
      "/en/team",
      "/de/team_kilianstauss",
      "/en/team_kilianstauss",
      "/de/team_kathrinstauss",
      "/en/team_kathrinstauss",
      "/de/team_janisnecker",
      "/en/team_janisnecker",
      "/de/team_samanthamariuzzi",
      "/en/team_samanthamariuzzi",
      "/de/team_telmogermano",
      "/en/team_telmogermano",
    ];
    response.data.list.sort(function (a, b) {
      return order.indexOf(a.route) - order.indexOf(b.route);
    });
    var apiTeam = response.data.list[0];
    var apiMembers = response.data.list.slice(1);
    logConsole && console.log("apiTeam", apiTeam);
    logConsole && console.log("apiMembers", apiMembers);
    return {
      team: cleanPageObject(apiTeam),
      members: apiMembers.map(function (apiMember) {
        return cleanPageObject(apiMember);
      }),
    };
  } else {
    return cleanPageObject(response.data);
  }
}

// -----------------------------------------------
// Helper functions
// -----------------------------------------------

function cleanPageObject(apiPage) {
  return {
    title: apiPage.h1,
    text: apiPage.text,
    imgs: apiPage.media.map(function (e) {
      return cleanImgObject(e);
    }),
  };
}

/**
 * languages [] languages to search for
 */
export const contentVersionsTitle = (apiContent, languages = ["de", "en"]) => {
  var apiTitles = apiContent.projects;
  if (!apiTitles || apiTitles.length === 0) {
    return {};
  } else {
    var titles = {};
    languages.forEach(function (lang) {
      var langTitles = apiTitles.filter(function (l) {
        return l.project_lang === lang;
      });
      if (langTitles.length === 0) {
        titles[lang] = "";
        logConsole && console.log("item has no title for a lang (324987532)");
      } else {
        titles[lang] = langTitles[0].project_cont;
        if (langTitles.length > 1) {
          logConsole &&
            console.log(
              "!------- item '" +
                apiContent.id +
                "' has multiple titles for lang " +
                lang +
                ":",
              apiContent.projects
            );
        }
      }
    });
    return titles;
  }
};

function contentVersionsLinks(apiContentItem, languages = ["de", "en"]) {
  var links = {};
  var apiLinks = apiContentItem.links;
  languages.forEach(function (lang) {
    links[lang] = apiLinks.filter(function (l) {
      return l.link_lang === lang;
    });
  });
  return links;
}

function cleanImgObject(apiMedia) {
  if (
    apiMedia.hasOwnProperty("media_object") &&
    apiMedia.media_object.hasOwnProperty("webCacheUrl")
  ) {
    return {
      thumbUrl: apiMedia.media_object.webCacheUrl.replace(
        "{convertKey}",
        convertKeyThumb
      ),
      fullUrl: apiMedia.media_object.webCacheUrl.replace(
        "{convertKey}",
        convertKeyFull
      ),
    };
  }
  logConsole &&
    console.log('Missing "media_object" or "webCacheUrl" property', apiMedia);
}

function itemPathFromVersions(apiContent, languages = ["de", "en"]) {
  const paths = {};
  const type = apiContent.content_type; // award, news, project, publication
  const category = apiContent.category; // null, furniture, graphic, interface, product, signage, spatial
  languages.forEach(function (lang) {
    let path = `/${lang}/`;
    path += `${mapTypeToPath[type][lang]}/`;
    if (category) {
      const metaItem = categoriesMeta.find((e) => e.id === category);
      if (!metaItem) throw new Error("wrong category name (32987562)");
      const categoryPath = metaItem.slug[lang];
      path += `${categoryPath}/`;
    }
    path += apiContent.slug;
    paths[lang] = path;
  });
  return paths;
}

export const contentTextVersions = (apiContent, languages = ["de", "en"]) => {
  // var texts = apiContent.texts;
  if (apiContent.texts.length === 0) {
    return {};
  } else {
    const texts = {};
    languages.forEach(function (lang) {
      var text = apiContent.texts.find((e) => e.text_lang === lang);
      if (text) {
        texts[lang] = text.text_cont;
      }
    });
    return texts;
  }
};

function extractVideos(apiContentItem) {
  var vids = apiContentItem.media
    .map(function (e, i) {
      var vimeoCode; // will be e.g. "487252938"
      var ratioNum; // will be e.g. 56.25
      if (e.hasOwnProperty("media_link")) {
        // --- vimeoCode
        if (e.media_link.match(/^[0-9]{6,11}$/)) {
          vimeoCode = e.media_link; // already a vimeo code
        } else {
          var vimeoCodeRegex =
            /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/;
          var search = e.media_link.match(vimeoCodeRegex);
          if (search) {
            vimeoCode = search[5];
          }
        }

        // --- ratio
        var ratio = "16:9";
        if (e.hasOwnProperty("media_alt")) {
          if (e.media_alt.match(/^[0-9]+:[0-9]+$/)) {
            ratio = e.media_alt;
          }
        }
        var nums = ratio.match(/^([0-9]+):([0-9]+)$/);
        ratioNum = (+nums[2] / +nums[1]) * 100;
        logConsole && console.log(ratioNum, e);
      }
      if (vimeoCode && ratioNum) {
        return {
          vimeoId: vimeoCode,
          ratio: ratioNum,
        };
      }
      return "~~NO~~";
    })
    .filter(function (e) {
      return e !== "~~NO~~";
    });
  return vids;
}

/**
eg. image1.jpg 300w, image2.jpg 600w, image3.jpg 900w

  ["r_579x0.jpg", "579w"],
  ["r_680x0.jpg", "680w"],
  ["r_1120x0.jpg", "1120w"],

BUT !!! check what input it wants before use.

*/
export const lazySrcsetImgObject = (apiMedia) => {
  if (
    apiMedia.hasOwnProperty("media_object") &&
    apiMedia.media_object.hasOwnProperty("webCacheUrl")
  ) {
    var srcset = convertKeysSrcset.map(function (d) {
      var convKey = d[0];
      var w = d[1];
      var url = apiMedia.media_object.webCacheUrl.replace(
        "{convertKey}",
        convKey
      );
      return url + " " + w;
    });
    return {
      fallbackImgSrc: apiMedia.media_object.webCacheUrl.replace(
        "{convertKey}",
        convertKeyThumb
      ),
      srcset: srcset.join(", "),
    };
  }
  throw new Error('Missing "media_object" or "webCacheUrl" property');
};
