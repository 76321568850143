import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Context } from "./Store";
import { useLocation } from "react-router-dom";
import ContentArea from "./components/ContentArea";
import Menu from "./components/Menu";
import VerticalBar from "./components/VerticalBar";
import Grid from "./components/Grid";
import Header from "./components/Header";
// import Sandbox from "./Sandbox";
import { getItems } from "./util/api";
import { logConsole, logRenders } from "./util/global";
import Dot from "./components/Dot";
import CookieConsent from "./components/CookieConsent";
import { GridContext } from "./components/GridContext";

const App = ({ lang }) => {
  logRenders && logConsole && console.log("RENDER — App component", lang);

  const [state, dispatch] = useContext(Context);
  const { gridVisible } = useContext(GridContext);

  const items = state.items;
  useEffect(() => {
    getItems(function (items) {
      logConsole && console.log(items);
      setLoading(false);
      const shuffled = items.sort(() => 0.5 - Math.random());
      dispatch({ type: "SET_ITEMS", payload: shuffled });
    });

    // --- detect touch devices
    window.addEventListener(
      "touchstart",
      function onFirstTouch() {
        window.USER_CAN_TOUCH = true;
        window.removeEventListener("touchstart", onFirstTouch, false);
      },
      false
    );
  }, []);

  const [loading, setLoading] = useState(true);
  // const [routeId, setRouteId] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [imgLeft, setImgLeft] = useState(null);
  const [imgRight, setImgRight] = useState(null);
  const [vBarTemp, setVBarTemp] = useState(null);
  const [listScrollPos, setListScrollPos] = useState({});
  const [vBarHard, setVBarHard] = useState({
    top: "",
    year: "",
    bottom: "",
    center: "",
  });
  const location = useLocation();
  // if (!items || items.length === 0) {
  //   return (
  //     <div>loading</div>
  //   );
  // }

  const vb = {
    top: vBarTemp ? vBarTemp.top : vBarHard.top,
    year: vBarTemp ? vBarTemp.year : vBarHard.year,
    bottom: vBarTemp ? vBarTemp.bottom : vBarHard.bottom,
    center: vBarTemp ? vBarTemp.center : vBarHard.center,
  };

  logConsole && console.log(">>>>> ----- vBarTemp", vBarTemp);

  return (
    <>
      <div id="app-bg"></div>
      <div id="header-grad"></div>
      {(!items || items.length === 0) && (
        <div id="spinner">
          <Dot />
        </div>
        // <div>loading</div>
      )}
      {items.length > 0 && (
        <>
          {imgLeft && <BgimgLeft img={imgLeft} blur={gridVisible} />}
          {imgRight && <BgimgRight img={imgRight} blur={menuOpen} />}
          <div id="ui-fixed">
            <Header
              items={items}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              location={location}
              lang={lang}
            />
            <VerticalBar
              top={vb.top}
              year={vb.year}
              bottom={vb.bottom}
              center={vb.center}
            />
            <Menu open={menuOpen} setMenuOpen={setMenuOpen} lang={lang} />
            <Grid items={items} setVBarTemp={setVBarTemp} lang={lang} />
          </div>
          <ContentArea
            lang={lang}
            setMenuOpen={setMenuOpen}
            dataLoading={loading}
            dataMenuOpen={menuOpen}
            items={items}
            setVBarTemp={setVBarTemp}
            setImgLeft={setImgLeft}
            setImgRight={setImgRight}
            // setRouteId={setRouteId}
            setVBarHard={setVBarHard}
            listScrollPos={listScrollPos}
            setListScrollPos={setListScrollPos}
          />
        </>
      )}
      <CookieConsent lang={lang} />
    </>
  );
};

function BgimgLeft({ img, blur }) {
  const url = img === null ? "none" : img;
  return (
    <div
      id="fixed-bg-img-left"
      className={blur ? "blurred" : ""}
      style={{ backgroundImage: `url(${url})` }}
    />
  );
}

function BgimgRight({ img, blur }) {
  const url = img === null ? "none" : img;
  return (
    <div
      id="fixed-bg-img-right"
      className={blur ? "blurred" : ""}
      style={{ backgroundImage: `url(${url})` }}
    />
  );
}

export default App;
