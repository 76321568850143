import React, { useContext, useEffect } from "react";
import {
  useLocation,
  useMatch,
  Link,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { langRoute, dictionary } from "../util/lang";
import { logConsole, logRenders } from "../util/global";
import { scrollToPixel, scrollToTop } from "../util/functions";
import Home from "./Home";
import Item from "./Item";
import Page from "./Page";
import AdLanding from "./AdLanding";
import CategoryLanding from "./CategoryLanding";
import List from "./List";
import Err404 from "./Err404";
import { GridContext } from "./GridContext";

// const Err404 = () => (<div>404 (ContentArea) (39287548)</div>);

const ContentArea = ({
  lang,
  items,
  setVBarHard,
  setImgLeft,
  setImgRight,
  setVBarTemp,
  setMenuOpen,
  dataLoading,
  /*setRouteId,*/ dataMenuOpen,
  listScrollPos,
  setListScrollPos,
}) => {
  logRenders && logConsole && console.log("RENDER — ContentArea component");
  const { setGridClickable, setGridVisible, gridVisible } =
    useContext(GridContext);

  const location = useLocation();
  const isLanding = useMatch(`/${langRoute("projects", lang)}/:category`);
  const isAd = useMatch(`/${langRoute("x", lang)}/:category`);
  const isHome = useMatch(`/${lang}`);
  const isBlurred =
    (gridVisible && !isLanding && !isHome && !isAd) ||
    dataLoading ||
    dataMenuOpen;
  const noscroll = gridVisible || dataLoading || dataMenuOpen;

  // LIST SCROLL
  const matchedList = [
    { listName: "project", match: useMatch(`/${langRoute("projects", lang)}`) },
    { listName: "award", match: useMatch(`/${langRoute("awards", lang)}`) },
    { listName: "news", match: useMatch(`/${langRoute("news", lang)}`) },
    {
      listName: "publication",
      match: useMatch(`/${langRoute("publications", lang)}`),
    },
  ].find((e) => e.match !== null);
  const list = matchedList !== undefined ? matchedList.listName : false;

  logConsole && console.log("--------------------------------");
  logConsole && console.log("IS LIST: ", list);
  logConsole && console.log("positions: ", listScrollPos);
  logConsole && console.log("--------------------------------");

  useEffect(() => {
    setImgRight(null);
    setMenuOpen(false);
    if (!isLanding) {
      setGridVisible(false);
    }
    if (list && listScrollPos.hasOwnProperty(list)) {
      const scrollAmount = listScrollPos[list];
      scrollToPixel(scrollAmount);
    } else {
      scrollToTop();
    }
  }, [location.pathname]);

  useEffect(() => {
    document.body.classList.toggle("noscroll", noscroll);
  });

  if (!items || items.length === 0) {
    return <div>{"Loading"}</div>;
  }

  const listProps = {
    setImgLeft,
    setVBarTemp,
    setVBarHard,
    items,
    listScrollPos,
    setListScrollPos,
  };
  const itemProps = { setImgLeft, setVBarHard, items };
  // const homeProps = {setImgRight, setGridVisible, setGridFilter};
  const pageProps = {
    setVBarHard,
    setImgLeft,
  };

  return (
    <div
      id="content"
      // data-loading={dataLoading}
      // data-menu-open={dataMenuOpen}
      // data-grid-visible={gridVisible}
      data-blurred={isBlurred}
    >
      {lang === "en" && (
        <Routes>
          <Route path="home" element={<Navigate to="/" />} />
          <Route path="/" element={<Home lang="en" {...pageProps} />} />
          <Route
            path="projects"
            element={<List lang="en" filter="project" {...listProps} />}
          />
          <Route
            path="awards"
            element={<List lang="en" filter="award" {...listProps} />}
          />
          <Route
            path="publications"
            element={<List lang="en" filter="publication" {...listProps} />}
          />
          <Route
            path="news"
            element={<List lang="en" filter="news" {...listProps} />}
          />
          <Route
            path="search/:searched"
            element={<List lang="en" filter="search" {...listProps} />}
          />
          <Route
            path="awards/:slug"
            element={<Item lang="en" type="award" {...itemProps} />}
          />
          <Route
            path="publications/:slug"
            element={<Item lang="en" type="publication" {...itemProps} />}
          />
          <Route
            path="news/:slug"
            element={<Item lang="en" type="news" {...itemProps} />}
          />
          <Route
            path="projects/:category/:slug"
            element={<Item lang="en" type="project" {...itemProps} />}
          />
          <Route
            path="x/:category"
            element={<AdLanding lang="en" {...pageProps} />}
          />
          <Route
            path="projects/:category"
            element={<CategoryLanding lang="en" {...pageProps} />}
          />
          <Route
            path="team"
            element={<Page lang="en" id="team" {...pageProps} />}
          />
          <Route
            path="about"
            element={<Page lang="en" id="about" {...pageProps} />}
          />
          <Route
            path="contact"
            element={<Page lang="en" id="contact" {...pageProps} />}
          />
          <Route
            path="privacy"
            element={<Page lang="en" id="privacy" {...pageProps} />}
          />
          <Route
            path="imprint"
            element={<Page lang="en" id="imprint" {...pageProps} />}
          />
          <Route path="404" element={<Err404 lang="en" />} />
          <Route path="*" element={<Err404 lang="en" />} />
        </Routes>
      )}
      {lang === "de" && (
        <Routes>
          {/* turn all elements to routes */}
          <Route path="home" element={<Navigate to="/" />} />
          <Route path="/" element={<Home lang="de" {...pageProps} />} />
          <Route
            path="projekte"
            element={<List lang="de" filter="project" {...listProps} />}
          />
          <Route
            path="preise"
            element={<List lang="de" filter="award" {...listProps} />}
          />
          <Route
            path="publikationen"
            element={<List lang="de" filter="publication" {...listProps} />}
          />
          <Route
            path="news"
            element={<List lang="de" filter="news" {...listProps} />}
          />
          <Route
            path="search/:searched"
            element={<List lang="de" filter="search" {...listProps} />}
          />
          <Route
            path="preise/:slug"
            element={<Item lang="de" type="award" {...itemProps} />}
          />
          <Route
            path="publikationen/:slug"
            element={<Item lang="de" type="publication" {...itemProps} />}
          />
          <Route
            path="news/:slug"
            element={<Item lang="de" type="news" {...itemProps} />}
          />
          <Route
            path="projekte/:category/:slug"
            element={<Item lang="de" type="project" {...itemProps} />}
          />
          <Route
            path="x/:category"
            element={<AdLanding lang="de" {...pageProps} />}
          />
          <Route
            path="projekte/:category"
            element={<CategoryLanding lang="de" {...pageProps} />}
          />
          <Route
            path="team"
            element={<Page lang="de" id="team" {...pageProps} />}
          />
          <Route
            path="philosophie"
            element={<Page lang="de" id="about" {...pageProps} />}
          />
          <Route
            path="kontakt"
            element={<Page lang="de" id="contact" {...pageProps} />}
          />
          <Route
            path="datenschutz"
            element={<Page lang="de" id="privacy" {...pageProps} />}
          />
          <Route
            path="impressum"
            element={<Page lang="de" id="imprint" {...pageProps} />}
          />
          <Route path="404" element={<Err404 lang="de" />} />
          <Route path="*" element={<Err404 lang="de" />} />
        </Routes>
      )}
      {isAd === false && (
        <div id="fix-bottom">
          <Link
            className="font-s white50"
            to={`/${langRoute("imprint", lang)}`}
          >
            {dictionary.menu_imprint[lang]}
          </Link>
        </div>
      )}
    </div>
  );
};

export default React.memo(ContentArea);
