import { logConsole } from "../util/global";
import { useState, useEffect, useContext } from "react";
import { getPage, mapCategoryToPage } from "../util/api";
import { isValidCategory, getCategoryId } from "../util/functions";
import Dot from "./Dot";
import { useParams } from "react-router-dom";
import { GridContext } from "./GridContext";

const CategoryLanding = ({ lang, setVBarHard }) => {
  const { category } = useParams();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(null);
  const pageId = mapCategoryToPage(category, lang);
  const { setGridClickable, setGridFilter, setGridVisible } =
    useContext(GridContext);

  useEffect(() => {
    const categoryId = getCategoryId(category, lang);
    setVBarHard({ top: categoryId, year: "", bottom: "" });
    setGridClickable(true);
    setGridVisible(true);
    setGridClickable(true);
    setGridFilter(categoryId);
    getPage(pageId, lang, (page) => {
      setLoading(false);
      setPage(page);
    });
  }, [category]);

  useEffect(() => {
    setGridClickable(true);
    setGridVisible(true);
  });

  if (!isValidCategory(category, lang)) {
    throw new Error(`(03827645) invalid catoegory '${category}' for '${lang}'`);
  }
  if (loading || !page) {
    return (
      <div id="spinner">
        <Dot />
      </div>
    );
  }
  logConsole && console.log("page", page);
  return (
    <div className="category-landing">
      <div
        className="bg"
        style={{ backgroundImage: `url(${page.imgs[0].fullUrl})` }}
      />
      <div className="text" dangerouslySetInnerHTML={{ __html: page.text }} />
    </div>
  );
};

export default CategoryLanding;
