import React, {
  Fragment,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import Hamburger from "./Hamburger";
import { langRoute } from "../util/lang";
import larr from "../assets/images/larr.svg";
import rarr from "../assets/images/rarr.svg";
import { logConsole, logRenders } from "../util/global";
import { getDateVal } from "../util/functions";
import { GridContext } from "./GridContext";

const Header = ({ items, menuOpen, setMenuOpen, location, lang }) => {
  logRenders && logConsole && console.log("RENDER — Header component");

  const {
    gridVisible,
    setGridVisible,
    gridFilter,
    setGridFilter,
    setGridClickable,
    gridClickable,
  } = useContext(GridContext);

  const [arrowsMarkup, setArrowsMarkup] = useState({
    desktop: null,
    mobile: null,
  });

  let { pathname } = useLocation();
  const matched = useMemo(
    () =>
      [
        {
          type: "project",
          match: matchPath(
            `/${langRoute("projects", lang)}/:category/:slug`,
            pathname
          ),
        },
        {
          type: "award",
          match: matchPath(`/${langRoute("awards", lang)}/:slug`, pathname),
        },
        {
          type: "news",
          match: matchPath(`/${langRoute("news", lang)}/:slug`, pathname),
        },
        {
          type: "publication",
          match: matchPath(
            `/${langRoute("publications", lang)}/:slug`,
            pathname
          ),
        },
      ].find((e) => e.match !== null),
    [pathname, lang]
  );

  logConsole && console.log("-------->", matched);

  useEffect(() => {
    if (items.length && matched) {
      logConsole &&
        console.log(
          "matched: ",
          matched.type,
          ">",
          matched.match.params.slug,
          "(",
          matched,
          ")"
        );

      // A - old - remain in same category (projects)
      // const currentItems = (gridFilter && matched.type === "project")
      //   ? items.filter(e => e.category === gridFilter)
      //   : (matched.type !== "project")
      //   ? items.filter(e => e.type === matched.type)
      //   : items;

      // B - show like in list (projects and all other types)
      const currentItemsUnsorted = matched.type
        ? items.filter((e) => e.type === matched.type)
        : items;
      const currentItems = currentItemsUnsorted.sort(
        (a, b) => getDateVal(b) - getDateVal(a)
      );

      const currentIndex = currentItems.findIndex(
        (e) => e.slug === matched.match.params.slug
      );
      // const currentIndex = items.findIndex(d => d.originalId === currentItem.originalId);
      const prevIndex =
        currentIndex === 0 ? currentItems.length - 1 : currentIndex - 1;
      const nextIndex =
        currentIndex >= currentItems.length - 1 ? 0 : currentIndex + 1;
      const prevItem = currentItems[prevIndex];
      const nextItem = currentItems[nextIndex];

      if (prevItem && nextItem) {
        setArrowsMarkup({
          desktop: (
            <Fragment>
              <Link className="d-none d-md-flex" to={prevItem.path[lang]}>
                <img src={larr} alt="left arrow" />
              </Link>
              <Link className="d-none d-md-flex" to={nextItem.path[lang]}>
                <img src={rarr} alt="right arrow" />
              </Link>
            </Fragment>
          ),
          mobile: (
            <div className="arrows-mobile d-md-none">
              <Link to={prevItem.path[lang]}>
                <img src={larr} alt="left arrow" />
              </Link>
              <Link to={nextItem.path[lang]}>
                <img src={rarr} alt="right arrow" />
              </Link>
            </div>
          ),
        });
      }
    } else {
      setArrowsMarkup({
        desktop: null,
        mobile: null,
      });
    }
  }, [location, items, lang, matched]);

  const handleDotsClick = () => {
    setGridClickable(true);

    if (gridVisible && gridFilter && matched.type !== "project") {
      setGridFilter(null);
    } else {
      setGridVisible(!gridVisible);
    }
  };

  return (
    <nav id="header">
      <div className="left">
        <Link to={`/${lang}`} id="logo-pt1" className="font-s">
          stauss
        </Link>
        <Link to={`/${lang}`} id="logo-pt2" className="font-s">
          process<strong>form</strong>&nbsp;gmbh
        </Link>
      </div>
      <div className="right">
        <div className="l">{arrowsMarkup.desktop}</div>
        <div className="r">
          <nav id="dots" onClick={handleDotsClick} />
          <Hamburger isOpen={menuOpen} callback={setMenuOpen} />
        </div>
      </div>
      {arrowsMarkup.mobile}
    </nav>
  );
};

export default React.memo(Header);
