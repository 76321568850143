import { useState, useEffect, Fragment, useContext } from "react";
import { getPage } from "../util/api";
import { menuItems, logRenders, logConsole } from "../util/global";
import { GridContext } from "./GridContext";
import LazyImage from "./LazyImage";
// import {dictionary} from "../util/lang";

const PageGeneric = ({ data, setImgLeft }) => {
  const hasFirstImg = data.imgs && data.imgs.length > 0;

  useEffect(() => {
    if (hasFirstImg) {
      setImgLeft(data.imgs[0].fullUrl);
    }
  }, [data]);

  return (
    <div className="page">
      {data.title && <h1>{data.title}</h1>}
      {hasFirstImg && (
        <LazyImage
          src={data.imgs[0].fullUrl}
          id={`page-img-0`}
          className="d-xl-none"
        />
      )}
      <div
        className="text-webpblpage"
        dangerouslySetInnerHTML={{ __html: data.text }}
      />
    </div>
  );
};

const PageTeam = ({ data, setImgLeft }) => {
  const hasFirstImg = data.team && data.team.imgs && data.team.imgs.length > 0;
  useEffect(() => {
    if (hasFirstImg) {
      setImgLeft(data.team.imgs[0].fullUrl);
    }
  }, [data]);
  return (
    <div className="page">
      {hasFirstImg && (
        <div className="img-first">
          <LazyImage
            src={data.team.imgs[0].fullUrl}
            id={`page-team-0`}
            className="d-xl-none"
          />
        </div>
      )}
      {data.team && (
        <div
          className="text-webpblpage"
          dangerouslySetInnerHTML={{ __html: data.team.text }}
        />
      )}
      {data.members &&
        data.members.map((member, i) => (
          <Fragment key={i}>
            <div className="spacer my-space"></div>
            {member.imgs.length > 0 && (
              <LazyImage
                src={member.imgs[0].fullUrl}
                key={`page-member-${i}`}
                id={`page-member-${i}`}
              />
            )}
            <div className="text-webpblpage">
              <h2>{member.title}</h2>
              <div
                // className="text-webpblpage"
                dangerouslySetInnerHTML={{ __html: member.text }}
              />
            </div>
          </Fragment>
        ))}
    </div>
  );
};

const Page = ({ id, lang, setVBarHard, setImgLeft }) => {
  logRenders && logConsole && console.log("RENDER - Page component");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({});
  const { setGridClickable } = useContext(GridContext);

  useEffect(() => {
    const menuItem = menuItems.find((e) => e.id === id);
    if (!menuItem) {
      throw new Error(`(98759097) no menu item with id ${id}`);
    }
    const title = menuItem.pageTitle[lang];
    // const title = dictionary[id]).pageTitle[lang];
    setVBarHard({ top: title, year: "", bottom: "" });
    getPage(id, lang, (page) => {
      logConsole && console.log("Clean page:", page);
      setLoading(false);
      setGridClickable(false);
      setPage(page);
      setImgLeft();
    });
  }, [id, lang, setImgLeft, setVBarHard, setGridClickable]);

  if (loading || !page) {
    return <div>{`Loading page '${id}'...`}</div>;
  }

  switch (id) {
    case "team":
      return <PageTeam data={page} setImgLeft={setImgLeft} />;
    default:
      return <PageGeneric data={page} setImgLeft={setImgLeft} />;
    // return null;
  }
};

export default Page;
