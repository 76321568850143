import { categoriesMeta, adMeta } from "./global";

// -----------------------------------------------
// Languages management
// -----------------------------------------------

export const langRoute = (route, lang) => {
  const routes = {
    x: {
      en: "x",
      de: "x",
    },
    projects: {
      en: "projects",
      de: "projekte",
    },
    awards: {
      en: "awards",
      de: "preise",
    },
    publications: {
      en: "publications",
      de: "publikationen",
    },
    news: {
      en: "news",
      de: "news",
    },
    team: {
      en: "team",
      de: "team",
    },
    about: {
      en: "about",
      de: "philosophie",
    },
    contact: {
      en: "contact",
      de: "kontakt",
    },
    privacy: {
      en: "privacy",
      de: "datenschutz",
    },
    imprint: {
      en: "imprint",
      de: "impressum",
    },
    search: {
      en: "search",
      de: "search",
    },
  };
  if (!routes[route]) {
    throw new Error(`(29875209) No route named '${route}'`);
  } else if (!routes[route][lang]) {
    throw new Error(`(29835209) No route named '${lang}/${route}'`);
  }
  return `${lang}/${routes[route][lang]}`;
};

export const dictionary = {
  // --- USED

  showMore: {
    de: "weiterlesen",
    en: "show more",
  },
  showLess: {
    de: "ausblenden",
    en: "show less",
  },

  menu_projects: {
    en: "projects",
    de: "projekte",
  },
  menu_awards: {
    en: "awards",
    de: "preise",
  },
  menu_publications: {
    en: "publications",
    de: "publikationen",
  },
  menu_news: {
    en: "news",
    de: "news",
  },
  menu_team: {
    en: "team",
    de: "team",
  },
  menu_about: {
    en: "about",
    de: "philosophie",
  },
  menu_contact: {
    en: "contact",
    de: "kontakt",
  },
  menu_privacy: {
    en: "privacy",
    de: "datenschutz",
  },
  menu_imprint: {
    en: "imprint",
    de: "impressum",
  },
  menu_search: {
    en: "search",
    de: "search",
  },

  landingAboutIncipit: {
    de: "Wir feiern – zusammen mit Ihnen – im Jahr 2021 den 25. Geburtstag unseres 1996 gegründeten Designbüros und freuen uns über das bisher mit Ihnen Erreichte!",
    en: "We will celebrate - together with you - in 2021 the 25th birthday of our design office, founded in 1996, and we are happy about what we have achieved with you so far!",
  },
  project: {
    de: "projekt",
    en: "project",
  },
  award: {
    de: "preis",
    en: "award",
  },
  publication: {
    de: "publikation",
    en: "publication",
  },
  news: {
    de: "news",
    en: "news",
  },
};

export const getTranslatedCategoryPath = (categoryId, lang) => {
  const projPiece = { en: "projects", de: "projekte" };
  const metaItem = categoriesMeta.find((e) => e.id === categoryId);
  if (!metaItem) throw new Error("wrong category name (92387546)");
  return `/${lang}/${projPiece[lang]}/${metaItem.slug[lang]}`;
};

export const getTranslatedAdPath = (categoryId, lang) => {
  const projPiece = { en: "x", de: "x" };
  const metaItem = adMeta.find((e) => e.id === categoryId);
  if (!metaItem) throw new Error("wrong ad name (92387546)");
  return `/${lang}/${projPiece[lang]}/${metaItem.slug[lang]}`;
};

export const switchLang = (path, lfrom, lto) => {
  if (path === `/${lfrom}` || path === `/${lfrom}/`) {
    return `/${lto}`;
  }
  if (path.substring(0, 3) !== `/${lfrom}`) {
    throw new Error(`(29784563) '${path}' is not a valid '/${lfrom}/' path`);
  }

  const pathWithoutLang = path.substring(3);

  // --- has category

  let hasCategory = false;
  let categoryPath = null;
  let translatedCategoryPath = null;
  // const projPiece = {"en": "projects", "de": "projekte"};
  categoriesMeta.forEach((e) => {
    const thisCategoryPath = getTranslatedCategoryPath(e.id, lfrom); //`/${lfrom}/${projPiece[lfrom]}/${e.slug[lfrom]}`;
    if (path.indexOf(thisCategoryPath) === 0) {
      hasCategory = true;
      categoryPath = thisCategoryPath;
      translatedCategoryPath = getTranslatedCategoryPath(e.id, lto); // `/${lto}/${projPiece[lto]}/${e.slug[lto]}`;
    }
  });
  adMeta.forEach((e) => {
    const thisCategoryPath = getTranslatedAdPath(e.id, lfrom); //`/${lfrom}/${projPiece[lfrom]}/${e.slug[lfrom]}`;
    if (path.indexOf(thisCategoryPath) === 0) {
      hasCategory = true;
      categoryPath = thisCategoryPath;
      translatedCategoryPath = getTranslatedAdPath(e.id, lto); // `/${lto}/${projPiece[lto]}/${e.slug[lto]}`;
    }
  });
  if (hasCategory) {
    return path.replace(categoryPath, translatedCategoryPath);
  }

  // --- other pages

  const routeMap = [
    { en: "home", de: "home" },
    { en: "/", de: "/" },
    { en: "x", de: "x" },
    { en: "projects", de: "projekte" },
    { en: "awards", de: "preise" },
    { en: "publications", de: "publikationen" },
    { en: "news", de: "news" },
    { en: "search", de: "search" },
    { en: "team", de: "team" },
    { en: "about", de: "philosophie" },
    { en: "contact", de: "kontakt" },
    { en: "privacy", de: "datenschutz" },
    { en: "imprint", de: "impressum" },
    { en: "404", de: "404" },
  ];
  const partToTranslate = /^\/([^/]+)/.exec(pathWithoutLang);
  if (!partToTranslate) {
    throw new Error("(24837920) no route match");
  }
  const item = routeMap.find((r) => r[lfrom] === partToTranslate[1]);
  const more = pathWithoutLang.substring(`/${partToTranslate[1]}`.length);
  const translated = `/${lto}/${item[lto]}` + (more ? more : "");
  // alert(path +" > "+ translated);
  return translated;
};
