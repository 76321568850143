import React, { useState, useEffect, Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { vBarValues } from "../util/functions";
import useDimensions from "react-cool-dimensions";
import { logConsole, logRenders } from "../util/global";
import { getTranslatedCategoryPath } from "../util/lang";
import Dot from "./Dot";
import useWindowSize from "../hooks/useWindowSize";
import { GridContext } from "./GridContext";

const NavDot = ({ category, filter, handleNavClick, lang }) => {
  const active = filter === category;
  const categorySlug = getTranslatedCategoryPath(category, lang); // categoriesMeta.find(e => e.id === category).slug[lang];
  return (
    <Link
      to={categorySlug}
      onClick={(e) => {
        handleNavClick(e, category);
      }}
      data-category={category}
      className={`nav-item${active ? " active" : ""}`}
    >
      <Dot />
    </Link>
  );
};

const Grid = ({ items, setVBarTemp, lang }) => {
  logRenders && logConsole && console.log("RENDER — Grid component");
  const {
    gridFilter: filter,
    setGridFilter: setFilter,
    gridClickable: clickable,
    setGridClickable,
    gridVisible: visible,
    setGridVisible,
  } = useContext(GridContext);

  const max = 2000;
  const renderItems = items.length > max ? items.slice(0, max) : items;
  const [cssGridSize, setCssGridSize] = useState(0);
  const [gridWrapperSizes, setGridWrapperSizes] = useState([0, 0]);

  const { ref } = useDimensions({
    onResize: ({ width, height }) => {
      logConsole && console.log(`Measured: ${width} × ${height}`);
      // const gridSize = calcGridSize(renderItems.length, width, height);
      // document.documentElement.style.setProperty("--grid-size", `${gridSize}px`);
      // logConsole && console.log("cssGridSize", cssGridSize);
      // setCssGridSize(gridSize);
      setGridWrapperSizes([width, height]);
    },
  });

  const [winWidth, winHeight] = useWindowSize();

  // set grid size the first time gridWrapperSizes are available
  useEffect(() => {
    if (gridWrapperSizes[0] === 0 || cssGridSize <= 12) {
      // if (!gridInitialized || cssGridSize === 0) {
      updateGridSize();
      logConsole && console.log("first ----- time");
    }
  }, [gridWrapperSizes]);

  // set grid size every time the window size changes
  useEffect(() => {
    updateGridSize();
  }, [winWidth, winHeight]);

  // every time the grid size changes
  useEffect(() => {
    if (cssGridSize > 0) {
      fitThumbsInsideGridLimits(countCols(), items.length);
      logConsole && console.log("Column count = ", countCols());
    }
  }, [cssGridSize]);

  function updateGridSize() {
    const gridSize = calcGridSize(
      renderItems.length,
      gridWrapperSizes[0],
      gridWrapperSizes[1]
    );
    document.documentElement.style.setProperty("--grid-size", `${gridSize}px`);
    setCssGridSize(gridSize);
    logConsole &&
      console.log("winWidth or winHeight change", winWidth, winHeight);
    logConsole &&
      console.log(
        "current grid wrapper size: ",
        gridWrapperSizes[0],
        gridWrapperSizes[1]
      );
    logConsole && console.log("cssGridSize", cssGridSize);
  }

  // ---------------------------------------------------------------------------
  // Grid event handlers
  // ---------------------------------------------------------------------------

  const highlightItem = (item) => {
    if (clickable) setVBarTemp(vBarValues(item, lang));
    document.getElementById(`thumb-${item.originalId}`).dataset.visible = "yes";
    document.getElementById(`dot-${item.originalId}`).dataset.touched = "yes";
  };

  const unhighlightItem = (item) => {
    if (clickable) setVBarTemp(null);
    document.getElementById(`thumb-${item.originalId}`).dataset.visible = "no";
    document.getElementById(`dot-${item.originalId}`).dataset.touched = "no";
  };

  // Unhighlights Items if the screen changes
  useEffect(() => {
    setVBarTemp(null);
    renderItems.forEach((item) => {
      unhighlightItem(item);
    });
  }, [clickable, visible]);

  const handleNavClick = (event, category) => {
    if (category !== filter || !visible) {
      setFilter(category);
    } else {
      event.preventDefault();
      setFilter(null);
    }
    setGridClickable(true);
    setGridVisible(true);
  };

  const props = { filter, lang, handleNavClick };

  const handleGridTouchMove = (event) => {
    // event.preventDefault();
    const clientX = event.touches[0].clientX;
    const clientY = event.touches[0].clientY;
    const el = document.elementFromPoint(clientX, clientY);
    if (el && el.classList && el.classList.contains("item")) {
      const originalId = el.dataset.originalId;
      const item = items.find((e) => e.originalId === originalId);
      if (window.touchedItemId !== originalId) {
        highlightItem(item);
        if (window.touchedItemId) {
          const unhilightItem = items.find(
            (e) => e.originalId === window.touchedItemId
          );
          unhighlightItem(unhilightItem);
        }
        window.touchedItemId = originalId;
      }
    }
    logConsole && console.log("window.touchedItemId", window.touchedItemId);
  };

  return (
    <Fragment>
      <div ref={ref} id="grid-wrapper" data-grid-visible={visible}>
        <div id="grid-thumbs">
          {renderItems.map((item, i) => {
            return (
              <span
                key={i}
                className="item"
                data-title={item.title[lang]}
                data-category={item.category}
              >
                <span
                  className={`thumb ${item.thumbX} ${item.thumbY}`}
                  id={`thumb-${item.originalId}`}
                  style={{
                    backgroundImage: `url('${item.firstImg.thumbUrl}')`,
                  }}
                  data-visible="no"
                ></span>
              </span>
            );
          })}
        </div>
        <div id="grid" onTouchMove={handleGridTouchMove}>
          {renderItems.map((item, i) => {
            const filterClass =
              filter === null
                ? false
                : item.category !== filter
                ? " hide"
                : " active";

            return clickable ? (
              <Link
                key={i}
                className={`item${filterClass ? filterClass : ""}`}
                to={item.path[lang]}
                onMouseEnter={() => {
                  highlightItem(item, clickable);
                }}
                onMouseLeave={() => {
                  unhighlightItem(item, clickable);
                }}
                id={`dot-${item.originalId}`}
                data-title={item.title[lang]}
                data-category={item.category}
                data-original-id={item.originalId}
              >
                <Dot />
              </Link>
            ) : (
              <span
                key={i}
                className={`item${filterClass ? filterClass : ""}`}
                onMouseEnter={() => {
                  highlightItem(item, clickable);
                }}
                onMouseLeave={() => {
                  unhighlightItem(item, clickable);
                }}
                id={`dot-${item.originalId}`}
                data-title={item.title[lang]}
                data-category={item.category}
                data-original-id={item.originalId}
              >
                <Dot />
              </span>
            );
          })}
        </div>
      </div>

      <div id="nav-dots">
        <NavDot category="graphic" {...props} />
        <NavDot category="spatial" {...props} />
        <NavDot category="signage" {...props} />
        <NavDot category="product" {...props} />
        <NavDot category="interface" {...props} />
        <NavDot category="furniture" {...props} />
      </div>
    </Fragment>
  );
};

export default React.memo(Grid);

const calcGridSize = (num, width, height) => {
  let gs = 12;
  let h = null;
  while (!h || h < height) {
    gs++;
    let cols = Math.floor(width / gs);
    let rows = Math.floor(num / cols);
    h = (rows + 1) * gs;
  }
  return gs - 1;
};

const countCols = () => {
  const domItemsNum = document.querySelectorAll("#grid .item").length;
  if (domItemsNum === 0) {
    return 0;
  }
  let cols = 0;
  let maxX = -1;
  let currX = 0;
  let i = 0;
  while (currX >= maxX && i < domItemsNum) {
    // Problem w/ safari see https://stackoverflow.com/questions/54790402/jquery-offset-inside-svg-is-not-working-in-safari
    // currX = $("#grid circle").eq(i).offset().left;
    currX = document.querySelectorAll("#grid .item").item(i).offsetLeft;
    if (currX > maxX) {
      maxX = currX;
      cols++;
    }
    i++;
  }
  return cols;
};

const fitThumbsInsideGridLimits = (colNum, totNum) => {
  document.querySelectorAll("#grid-thumbs .item .thumb").forEach((e, i) => {
    const clst = e.classList;
    clst.remove("no-top");
    clst.remove("no-bottom");
    clst.remove("no-left");
    clst.remove("no-right");
    // --- TOP
    if (i < colNum) {
      clst.add("no-bottom");
    }
    // --- LEFT
    if (i % colNum === 0) {
      clst.add("no-right");
    }
    // --- BOTTOM
    const remainder = totNum % colNum;
    const lastRow = remainder > 0 ? remainder : colNum;
    if (i >= totNum - lastRow) {
      clst.add("no-top");
    }
    // --- RIGHT
    if ((i + 1) % colNum === 0) {
      clst.add("no-left");
    }
  });
};
