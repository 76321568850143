import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";

const messages = {
  p: {
    en: "This site uses third-party website tracking technologies to provide and continually improve our services, and to display advertisements according to users interests.",
    de: "Diese Seite nutzt Website Tracking-Technologien von Dritten, um ihre Dienste anzubieten, stetig zu verbessern und Werbung entsprechend der Interessen der Nutzer anzuzeigen.",
  },
  customize: {
    en: "Customize",
    de: "Anpassen",
  },
  necessary: {
    en: "Necessary cookies",
    de: "Notwendige Cookies",
  },
  accept: {
    en: "Accept",
    de: "Akzeptieren",
  },
  decline: {
    en: "Decline",
    de: "Ablehnen",
  },
};

const consentOptions = [
  {
    label: "Google Analytics",
    event: "CookieOptInGoogleAnalytics",
    link: "https://policies.google.com/privacy",
  },
  {
    label: "Meta Pixel",
    event: "CookieOptInFacebookBasisCode",
    link: "https://www.facebook.com/privacy/policy/",
  },
];

const GTM = ({ events }) => {
  const prevEvents = useRef([]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
  }, []);

  useEffect(() => {
    if (events) {
      events.forEach((event) => {
        if (!prevEvents.current.includes(event)) {
          window.dataLayer.push({ event });
          prevEvents.current.push(event);
        }
      });
    }
  }, [events]);

  return (
    <Helmet>
      <script
        src="https://www.googletagmanager.com/gtag/js?id=G-7CYY3NFB8Z"
        async
      ></script>
    </Helmet>
  );
};

const CookieConsent = ({ lang }) => {
  const [necessaryConsent, setNecessaryConsent] = useState(false);
  const [gtmConsent, setGTMConsent] = useState(true);
  const [consentState, setConsentState] = useState(
    consentOptions.map((obj) => obj.event)
  );

  // initial load from local storage
  useEffect(() => {
    const consent = localStorage.getItem("consent");
    /* {
      necessary: true, // means user has seen cookie consent, we won't show it again
      gtm: true, // main consent, neccessary for gtm, user has not unchecked or declined it
      events: [] // equals consent state / consent options, events to be fired for gtm to load additional analytics t ools
    } */

    if (consent) {
      const data = JSON.parse(consent);

      setNecessaryConsent(data.necessary);
      setGTMConsent(data.gtm);
      setConsentState(data.events);
    }
  }, []);

  const handleConsent = (accepted) => {
    setNecessaryConsent(true);
    if (!accepted) {
      setGTMConsent(false);
      setConsentState([]);
    }

    localStorage.setItem(
      "consent",
      JSON.stringify({
        necessary: true,
        gtm: gtmConsent,
        events: gtmConsent ? consentState : [],
      })
    );
  };

  if (necessaryConsent)
    return <>{gtmConsent && <GTM events={consentState} />}</>;

  return (
    <div className="cookie-consent-outer">
      <div className="cookie-consent">
        <p className="text">{messages.p[lang]}</p>
        <div className="flex">
          <details>
            <summary>{messages.customize[lang]}</summary>
            <div className="checkboxes">
              <label className="checkbox main">
                <input type="checkbox" checked disabled />
                <span>{messages.necessary[lang]}</span>
                <span className="checkmark" />
              </label>
              <label className="checkbox main">
                <input
                  type="checkbox"
                  name="main"
                  checked={gtmConsent}
                  onChange={(e) => setGTMConsent(e.target.checked)}
                />
                <span>Google Tag Manager</span>
                <span className="checkmark" />
              </label>
              {consentOptions.map((option) => (
                <label className="checkbox" key={option.event}>
                  <input
                    type="checkbox"
                    name={option.event}
                    checked={gtmConsent && consentState.includes(option.event)}
                    onChange={(e) => {
                      e.target.checked
                        ? setConsentState([...consentState, option.event])
                        : setConsentState(
                            consentState.filter((item) => item !== option.event)
                          );
                    }}
                    disabled={!gtmConsent}
                  />
                  <span>{option.label}</span>
                  <span className="checkmark" />
                </label>
              ))}
            </div>
          </details>
          <div className="buttons">
            <button
              className="button-decline"
              onClick={() => handleConsent(false)}
            >
              {messages.decline[lang]}
            </button>
            <button
              className="button-accept"
              onClick={() => handleConsent(true)}
            >
              {messages.accept[lang]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
