import { adMeta, categoriesMeta } from "./global";
import { dictionary } from "./lang";

// -----------------------------------------------
// Functions
// -----------------------------------------------

export const isValidCategory = (categorySlug, lang) => {
  const found = categoriesMeta.find((e) => e.slug[lang] === categorySlug);
  return found !== undefined;
};

export const getAdId = (categorySlug, lang) => {
  const found = adMeta.find((e) => e.slug[lang] === categorySlug);
  if (found === undefined)
    throw new Error(`wrong ad slug '${categorySlug}' (38649025)`);
  return found.id;
};

export const getAdName = (categorySlug, lang) => {
  const found = adMeta.find((e) => e.slug[lang] === categorySlug);
  if (found === undefined)
    throw new Error(`wrong ad slug '${categorySlug}' (38649025)`);
  return found.name[lang];
};

export const getCategoryId = (categorySlug, lang) => {
  const found = categoriesMeta.find((e) => e.slug[lang] === categorySlug);
  if (found === undefined)
    throw new Error(`wrong category slug '${categorySlug}' (49023865)`);
  return found.id;
};

export const vBarValues = (item, lang) => {
  // logConsole && console.log("->", item.type)
  const top =
    item.type === "project" ? item.category : dictionary[item.type][lang];
  const year = item.year;
  // const bottom = <span>{item.title[lang]}<br/>{item.client}</span>;
  const bottom = (
    <div>
      <h1>{item.title[lang]}</h1>
      <h2>{item.client}</h2>
    </div>
  );
  return { top, year, bottom };
};

export const scrollToTop = () => {
  const content = document.querySelector("#content");
  try {
    content.scroll({
      // use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } catch (error) {
    content.scrollTo(0, 0); // fallback for older browsers
  }
};

export const scrollToPixel = (pixel) => {
  const content = document.querySelector("#content");
  try {
    content.scroll({
      // use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      top: pixel,
      left: 0,
      behavior: "smooth",
    });
  } catch (error) {
    content.scrollTo(0, 0); // fallback for older browsers
  }
};

// utility function to get a value from publication year and last updated date
export const getDateVal = ({ year, date }) =>
  [year, ...date.split("-")].reduce(
    (acc, curr, i) => acc + Number(curr) * Math.pow(100, 3 - i),
    0
  );
