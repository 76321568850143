import React from "react";
import { logRenders, logConsole } from "../util/global";
// import {use100vh} from "react-div-100vh";
import useWindowSize from "../hooks/useWindowSize";
import { langRoute } from "../util/lang";
import { useMatch } from "react-router-dom";

// // --- via https://stackoverflow.com/a/19014495/2501713
// function useWindowSize() {
//   const [size, setSize] = useState([0, 0]);
//   useLayoutEffect(() => {
//     function updateSize() {
//       setSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener('resize', updateSize);
//     updateSize();
//     return () => window.removeEventListener('resize', updateSize);
//   }, []);
//   return size;
// }

const VerticalBar = ({ top, year, bottom, center }) => {
  // x to be replaced with ${langRoute("x", lang)} if different for multi lang
  const useH1 = useMatch(`/:lang/x/:category`);

  logRenders && logConsole && console.log("RENDER - VerticalBar component");
  logConsole &&
    console.log(
      "Bottom value:",
      bottom.props ? bottom.props.children[0].props.children : bottom
    );

  // const height = use100vh();

  const [width, height] = useWindowSize();

  // let hhh = useCurrentHeight();

  return (
    <div
      id="vertical-bar"
      style={{
        width: height,
        transformOrigin: `${height / 2}px ${height / 2}px`,
      }}
    >
      <div className="bottom">{bottom}</div>

      <div className="year">{year}</div>
      {/*DEBUG <div className="year">{year +" • "+ height +" • "+ hhh}</div>*/}

      {useH1 ? (
        <h1 className="center">{center}</h1>
      ) : (
        <div className="center">{center}</div>
      )}
      <div className="top">{top}</div>
    </div>
  );
};

// export default React.memo(VerticalBar);
export default VerticalBar;

// via https://dev.to/vitaliemaldur/resize-event-listener-using-react-hooks-1k0c
// Also works and has a timeout

/*
const getWidth = () => window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

function useCurrentWidth() {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  return width;
}




const getHeight = () => window.innerHeight
  || document.documentElement.clientHeight
  || document.body.clientHeight;

function useCurrentHeight() {
  let [height, setHeight] = useState(getHeight());
  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setHeight(getHeight()), 150);
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])
  return height;
}

*/
